body,
html {
    height: 100%;
    color: #e5e5e5;
    background-color: #004488;
    font-size: 1rem;
    line-height: 1.875;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02rem;
}

* {
    box-sizing: border-box;
    margin: 0;
}

h1 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 3rem;
}

a {
    color: #E6FAFC;
    transition: color 0.3s ease-in-out;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    color: #e5e5e556;
}

.head-section {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding: 8rem 0;
    display: flex;
    align-items: center;
}

.head-section div.intro-head {
    display: inline-block;
    text-align: center;
    width: 100%;
    max-width: 38%;
    margin-right: 2%;
}

.head-section div.intro-head h1 {
    margin-bottom: -1.3rem;
    margin-top: -1.5rem;
    color: #ebf5ee;
}

.head-section div.intro-head h2 {
    margin-bottom: -0.5rem;
}

.head-section div.intro-head h3 {
    font-weight: 400;
}

.head-section div.intro-head div.intro-social {
    width: 11rem;
    margin: 0 auto;
    margin-top: 0.5rem;
}

.head-section div.intro-head div.intro-social a {
    margin-right: 1rem;
}

.head-section div.intro-head div.intro-social a:last-child {
    margin-right: 0rem;
}

.head-section div.intro-content {
    display: inline-block;
    width: 100%;
    max-width: 60%;
}

.head-section div.intro-content p {
    margin-bottom: 1rem;
}

.head-section div.intro-content div.skills-section .skills-lang {
    margin-right: 3rem;
}

.head-section div.intro-content div.skills-section .skills-lang,
.head-section div.intro-content div.skills-section .skills-framework {
    display: inline-block;
}

.head-section div.intro-content div.skills-section h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    color: #e5e5e5;
    margin-bottom: 0.3rem;
}

.head-section div.intro-content div.skills-section img {
    /*width: 3rem;*/
    height: 40px;
    display: inline-block;
    margin-right: 10px;
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: scale(1.2);
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #004488;
    color: #E6FAFC;
    text-align: center;
    border-radius: 6px;
    /* padding: 5px 0; */
    /* Position the tooltip */
    position: absolute;
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 99999;
}

.head-section div.intro-content div.skills-section img:last-child {
    margin-right: 0;
}

.about-section {
    color: #004488;
    background-color: #e5e5e5;
    padding: 3rem 0;
}

.work-section {
    color: #004488;
    background-color: #e5e5e5;
    padding: 6rem 0;
}

.work-section a {
    color: #3369a0;
    transition: color 0.3s ease-in-out;
}

.work-section a:hover {
    color: #7a99b3;
}

.work-section .work-container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.work-section .work-container h1 {
    margin-bottom: 2rem;
}

.work-section .work-container .proj-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.work-section .work-container .proj-container h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: -0.6rem;
    margin-right: 1.5rem;
    display: inline-block;
}

.work-section .work-container .proj-container .left {
    margin-right: 5%;
}

.work-section .work-container .proj-container .right {
    margin-left: 5%;
}

.work-section .work-container .proj-container .proj-lang {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.9rem;
    color: #3369a0;
}

.work-section .work-container .proj-container .proj-detail,
.work-section .work-container .proj-container .proj-screenshot {
    max-width: 45%;
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.work-section .work-container .proj-container .proj-screenshot img {
    width: 100%;
    transition: opacity 0.3s ease-in-out;
}

.work-section .work-container .proj-container .proj-screenshot:hover img {
    opacity: 0.7;
}

.footer-section {
    padding: 1.2rem 0;
}

.footer-section .footer-container {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
}

.footer-section .footer-container h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    letter-spacing: 0.03rem;
}

.footer-section .footer-container div {
    width: 11rem;
    margin: 0 auto;
}

.footer-section .footer-container div a {
    margin-right: 1rem;
}

.footer-section .footer-container div a:last-child {
    margin-right: 0rem;
}


.label, .Label {
    border-radius: 2px;
    /* color: #fff; */
    /* display: inline-block; */
    font-size: 12px;
    /* font-weight: 600; */
    /* line-height: 1; */
    padding: 3px 4px;
}

.v-align-middle {
    vertical-align: middle!important;
}
@media (max-width: 1100px) {
    .head-section {
        max-width: 90%;
    }
    .head-section div.intro-content div.skills-section .skills-lang {
        margin-right: 1rem;
    }
    .work-section .work-container {
        max-width: 90%;
    }
}

@media (max-width: 860px) {
    h1 {
        font-size: 2.2rem;
    }
    .head-section {
        display: block;
        padding: 3rem 0;
    }
    .head-section div.intro-head {
        display: block;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 3rem;
    }
    .head-section div.intro-content {
        display: block;
        max-width: 70%;
        text-align: center;
        margin: 0 auto;
    }
    .head-section div.intro-content p {
        margin-bottom: 3rem;
    }
    .head-section div.intro-content div.skills-section .skills-lang {
        margin-right: 0;
        display: block;
    }
    .work-section {
        padding: 3rem 0;
    }
    .work-section .work-container h1 {
        text-align: center;
    }
    .work-section .work-container .proj-container {
        flex-direction: column;
        max-width: 80%;
        margin: 0 auto;
    }
    .work-section .work-container .proj-container .proj-detail {
        max-width: 100%;
        order: 2;
        margin-bottom: 3rem;
    }
    .work-section .work-container .proj-container .proj-detail .proj-title {
        /* margin-bottom: 0.5rem; */
    }
    .work-section .work-container .proj-container .left {
        margin-right: 0;
    }
    .work-section .work-container .proj-container .right {
        margin-left: 0;
    }
    .work-section .work-container .proj-container .proj-screenshot {
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    .head-section div.intro-content {
        max-width: 90%;
    }
    .work-section .work-container .proj-container {
        max-width: 90%;
    }
    .work-section .work-container .proj-container .proj-title {
        text-align: center;
    }
    .responsibilities {
        visibility: hidden;
    }
}

@media (max-width: 450px) {
    .head-section div.intro-content {
        max-width: 100%;
    }
    .head-section div.intro-content p {
        margin-bottom: 1rem;
    }
    .work-section .work-container .proj-container {
        max-width: 100%;
    }
    .work-section .work-container .proj-container h2 {
        margin-right: 0.5rem;
    }
}